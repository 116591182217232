<template>
  <div class="comment-likes">
    <a class="comment-card-options-button" @click="like">
      <iconic v-if="loading" dcolor name="gspinner" />
      <span v-else :class="`${isLikeMe ? 'isLikeMe' : ''}`">{{ $locale.words["like"] }}</span>
    </a>
    <div class="comment-likes-figure" v-if="totalLikes">
      <iconic dcolor name="heart" />
      <span class="comment-likes-number">{{ totalLikes }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["comment"],
  data: function() {
    return {
      loading: false,
      likes: this.comment.likes,
      isLikeMe: this.comment.isLikeMe,
      totalLikes: this.comment.totalLikes,
    };
  },
  methods: {
    sendLike: async function() {
      try {
        return await this.$api.post(`${process.env.VUE_APP_API_HOST}comments/like`, { comment: this.comment._id });
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
    },
    like: async function() {
      this.isLoading(true);
      const like = await this.sendLike();

      if (like.data.success) {
        this.totalLikes = like.data.totalLikes;
        this.isLikeMe = like.data.action === "like";
      } else {
        this.ialert.go({ title: "Error", message: like.data.message });
      }

      this.isLoading(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-likes {
  @include Flex(inherit, inherit, center);
  gap: $mpadding;
  .isLikeMe {
    color: $primary-color;
  }
  &-figure {
    @include Flex(inherit, inherit, center);
    background-color: #fff;
    border-radius: 2pc;
    box-shadow: $dshadow;
    padding: $mpadding/3 $mpadding/2;
    gap: $mpadding/2;
  }
  &-number {
    font-size: 90%;
  }
}
</style>
